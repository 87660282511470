<template>
  <div id="navbar">
    <div id="nav-container" class="pc-center">
      <img src="@/assets/ArtMarco_logotype_ver.A_black-transparent.png">
      <nav>
        <router-link to="/">ARTMARCO&nbsp;/</router-link>
        <router-link to="/galeria">GALÉRIA&nbsp;/</router-link>
        <router-link to="/partneri">PARTNERI&nbsp;/</router-link>
        <router-link to="/kontakt">KONTAKT</router-link>
      </nav>
    </div>
  </div>
<!--
  <button @click="toggleNav" class="debug-center">CLICK ME</button>
-->
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Navbar',
  data: () => {
    return {
      navShown: false,
      oldScroll: 0
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    toggleNav () {
      if (this.navShown) {
        gsap.to(
          '#navbar',
          0.3,
          { opacity: 0, yPercent: -100, ease: 'power2.out' }
        )
      } else {
        gsap.to(
          '#navbar',
          0.3,
          { opacity: 1, yPercent: 0, ease: 'power2.out' }
        )
      }
      this.navShown = !this.navShown
    },
    onScroll () {
      if (window.scrollY < 80 && !this.navShown) {
        this.toggleNav()
      }
      if (window.scrollY > 80) {
        if (window.scrollY > this.oldScroll && this.navShown) {
          this.toggleNav()
        }
        if (window.scrollY < this.oldScroll && !this.navShown) {
          this.toggleNav()
        }
      }
      this.oldScroll = window.scrollY
    }
  }
}

</script>

<style scoped>

#navbar{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  min-width: 320px;
  max-width: var(--max-width-mobile);
  margin: auto;
  background-color: white;
  filter: drop-shadow(0 0 5px rgba(0,0,0,0.25));
}

#nav-container{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 10px 5%;
}

img {
  max-height: 50px;
  padding: 0 15px;
}

nav{
  white-space: nowrap;
}

nav a {
  position: relative;
  padding: 5px 2px;
  margin: auto auto;
  font-size: 0.75em;
  text-decoration: none;
  transition: 300ms ease;
}

nav a::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: black;
  transition: 300ms ease;
  transform: scale(0);
}

nav a:hover::after{
  transform: scale(1);
}

nav a.router-link-exact-active {
  color: darkslategrey;
}

nav a.router-link-exact-active::after {
  transform: scale(1);
}

/*IPhone SE (2.gen) a väčšie*/
@media only screen and (min-width: 375px) {
  nav a {
    padding: 5px 6px;
    font-size: 0.8em;
  }
}

/*Plná stránka - tablety, notebooky...*/
@media only screen and (min-width: 760px) {
  #navbar{
    max-width: var(--max-width-pc);
  }

  #nav-container{
    justify-content: flex-end;
  }

  img{
    margin-right: auto;
  }

  h3{
    max-width: 100px;
    margin-bottom: 0;
  }

  nav a {
    padding: 5px 10px;
  }
}

</style>
