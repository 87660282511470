<template>
  <Navbar/>
  <div class="app-content">
    <router-view/>
  </div>
  <Intro/>
</template>

<script>
import Navbar from './components/Navbar'
import Intro from './components/Intro'

export default {
  name: 'App',
  components: {
    Navbar,
    Intro
  }
}
</script>

<style>

#app{
  overflow-y: hidden;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Supreme', sans-serif;
  font-size: 18px;
  text-decoration: none;
  list-style-type: none;
  color: black;
  z-index: 10;
}

:root{
  --primary: #ffffff;
  --secondary: #28282d;
  --tertiary: #303030;

  --max-width-mobile: 500px;
  --max-width-pc: 1650px;
}text

p{
  color: darkslategrey;
}

.white-bg{
  background-color: white;
}

.pc{
  display: none;
}

.mobile{
  display: block;
}

.nav-spacer{
  display: block;
  width: 100%;
  height: 115px;
}

.app-content{
  max-width: var(--max-width-mobile);
  margin: auto;
  position: relative;
}

@media only screen and (min-width: 760px){
  *{
    font-size: 20px;
  }

  .pc{
    display: block;
  }

  .mobile{
    display: none;
  }

  .pc-center{
    width: 90%;
    margin: 0 auto;
  }

  .nav-spacer{
    height: 85px;
  }

  .app-content{
    max-width: var(--max-width-pc);
  }
}

.btn{
  transition: 300ms ease;
  color: black;
  padding: 5px 20%;
  border: 2px solid black;
  background-color: white;
  cursor: pointer;
}

.btn:hover{
  color: white;
  background-color: var(--tertiary);
}

.i-btn{
  transition: 300ms ease;
  color: white;
  padding: 5px 100px;
  border: 2px solid white;
  background-color: black;
  cursor: pointer;
}

.i-btn:hover{
  color: black;
  border: 2px solid black;
  background-color: white;
}

/*Triedy na testovanie*/

.debug-center{
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%)
}

</style>
