import { createRouter, createWebHistory } from 'vue-router'
import Artmarco from '../views/Artmarco'
import Partneri from '../views/Partneri'
import Galeria from '../views/Galeria'
import Kontakt from '../views/Kontakt'

const routes = [
  {
    path: '/',
    name: 'Artmarco',
    component: Artmarco
  },
  {
    path: '/partneri',
    name: 'Partneri',
    component: Partneri
  },
  {
    path: '/galeria',
    name: 'Galeria',
    component: Galeria
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: Kontakt
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
