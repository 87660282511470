<template>
  <footer ref="footer" @load="footerLoaded">
    <p>Toto sú hodnoty, ktoré nás vystihujú.</p>
    <h1>PRACUJEME, PRETOŽE&nbspNÁS&nbspTO&nbspBAVÍ</h1>
    <router-link to="/kontakt" class="i-btn">KONTAKT</router-link>
  </footer>
  <div class="ftr-spacer" ref="spacer"/>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Footer',
  components: {
  },
  methods: {
    onFooterResize () {
      this.$refs.spacer.style.height = this.$refs.footer.clientHeight + 'px'
    },
    onLoaded () {
      gsap.from('footer', {
        opacity: 0,
        scrollTrigger: {
          trigger: '.ftr-spacer',
          start: 'top bottom',
          end: 'top bottom-=' + this.$refs.footer.clientHeight.toString(),
          scrub: true
        }
      })
    }
  },
  mounted () {
    this.onFooterResize()

    window.addEventListener('resize', this.onFooterResize)
    window.addEventListener('load', this.onLoaded)
  },
  unmounted () {
    window.removeEventListener('resize', this.onFooterResize)
    window.removeEventListener('resize', this.onLoaded)
  }
}
</script>

<style scoped>

footer{
  position: fixed;
  max-width: var(--max-width-mobile);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background-color: black;
  margin: auto;
}

p{
  color: white;
  padding: 2.5% 2.5% 0 2.5%;
}

h1{
  color: white;
  font-size: 1.25em;
  padding: 10px 2.5% 0 2.5%;
}

a{
  display: inline-block;
  margin: 5% 2.5% 5% 2.5%;
}

@media only screen and (min-width: 760px){
  footer{
    max-width: var(--max-width-pc);
  }

  a{
    margin: 2.5% 2.5% 2.5% 2.5%;
  }
}

#ftr-spacer{
  width: 100vw;
  height: 100%;
}

</style>
