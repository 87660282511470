<template>
  <section class="intro">
        <span>
            <h6 class="quote1">MÁME RADI ARCHITEKTÚRU.</h6>
            <h6 class="quote2">ROZUMIEME INTERIÉROM.</h6>
            <h6 class="quote3">BAVÍ NÁS STAVAŤ...</h6>
        </span>
    <img src="@/assets/ArtMarco_logotype_ver.A_white-transparent.png">
    <div class="progress-bar">
      <div class="filler"></div>
    </div>
    <a @click="skipIntro" class="skip-btn">&gt;&gt;&gt;</a>
  </section>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'Intro',
  data: () => {
    return {
      introTimeline: {}
    }
  },
  methods: {
    skipIntro () {
      this.introTimeline.progress(1)
    }
  },
  created () {
    window.onload = () => {
      const introTimeline = gsap.timeline()

      gsap.from('.filler', {
        width: 0, duration: 10, ease: 'none'
      })

      introTimeline.to(
        '.quote1',
        { opacity: 1, ease: 'power1.out', duration: 1 },
        '+=1'
      )
      introTimeline.to(
        '.quote2',
        { opacity: 1, ease: 'power1.out', duration: 1 },
        '+=1'
      )
      introTimeline.to(
        '.quote3',
        { opacity: 1, ease: 'power1.out', duration: 1 },
        '+=1'
      )
      introTimeline.to(
        '.quote3, .quote2, .quote1',
        { opacity: 0, ease: 'power1.inOut', delay: 1, duration: 1 }
      )
      introTimeline.fromTo(
        '.intro img',
        { opacity: 0 },
        { opacity: 1, ease: 'power2.inOut', duration: 1 },
        '+=0.5'
      )
      introTimeline.to(
        '.intro',
        { opacity: 0, ease: 'power3.out', delay: 1, duration: 1.5 }
      )
      introTimeline.to(
        '.intro',
        { display: 'none', duration: 0 }
      )

      this.introTimeline = introTimeline
    }
  }
}
</script>

<style>

.intro{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 1000;
}

.intro span{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 80%;
  /* border: 3px white solid; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.intro h6{
  color: white;
  font-size: 1.5em;
  font-weight: normal;
  margin: 20px 20px;
  position: relative;
  opacity: 0;
}

.intro img{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  opacity: 0;
}

.progress-bar{
  display: none;
  position: fixed;
  top: 90%;
  left: 40%;
  right: 40%;
  bottom: 8.5%;
  border: 2px solid darkslategray;
  border-radius: 15px;
}

.filler{
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: darkslategray;
  margin: 2px 2px;
  border-radius: 5px;
}

.skip-btn{
  position: fixed;
  top: 85%;
  left: 40%;
  right: 40%;
  bottom: 12.5%;
  color: darkslategray;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: 300ms ease;
}

.skip-btn:hover{
  color: white;
}

</style>
