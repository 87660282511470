<template>
  <div class="info-one info">
    <div class="background">
      <div class="gradient-overlay"></div>
      <img :src="require('@/assets/photos/nzm/nz_bg3.jpg')" class=" u1">
    </div>
    <div class="text">
      <img src="@/assets/ArtMarco_logotype_ver.A_black-transparent.png" class="logo">
      <h1>ART-marco</h1>
      <p>Máme radi Architektúru. Rozumieme sa interiérom s presahom a baví nás stavať...</p>
    </div>
  </div>
  <div class="white-bg info-two info">
    <img src="@/assets/photos/brbyt/8.jpg" class="mobile m2" @load="m2">
    <div>
      <p>
        Toto všetko nás obklopuje a žijeme tým od roku 2010.

        Za túto dobu sme získali nespočet skúseností a kontaktov,
        vďaka ktorým máme dnes pochopenie pre Váš projekt a schopnosť byť Vám pevnou oporou.
      </p>
      <router-link to="/partneri" class="btn">PARTNERI</router-link>
    </div>
    <img src="@/assets/photos/brbyt/8.jpg" class="pc p2" @load="p2">
  </div>
  <div class="white-bg info-two info">
    <img src="@/assets/photos/nzm/nz11.jpg" class="pc p3" @load="p3">
    <div>
      <p>
        Je nám jedno či pre Vás staviame na klúč alebo realizujeme náročný interiér.
        Máme pochopenie pre atypické a neštandartné požiadavky z Vašej strany a zmysel pre detail.
      </p>
    </div>
    <img src="@/assets/photos/nzm/nz11.jpg" class="mobile m3" @load="m3">
  </div>
  <Footer/>
</template>

<script>
import Footer from '../components/Footer'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
  name: 'Artmarco',
  components: {
    Footer
  },
  data: () => {
    return {
      backgroundImg: ''
    }
  },
  created () {
    this.selectBackground()
  },
  mounted () {
    window.addEventListener('resize', this.selectBackground)

    gsap.registerPlugin(ScrollTrigger)
  },
  unmounted () {
    window.removeEventListener('resize', this.selectBackground)
  },
  methods: {
    selectBackground () {
      if (screen.width > 1200) {
        this.backgroundImg = 'nz_bg2.png'
      } else if (screen.width > 759) {
        this.backgroundImg = 'nz_bg1.png'
      } else {
        this.backgroundImg = 'nz_bg3.jpg'
      }
    },
    m2 () {
      gsap.from('.m2', {
        opacity: 0,
        y: 50,
        duration: 1,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: '.m2',
          start: 'top bottom-=100'
        }
      })
    },
    m3 () {
      gsap.from('.m3', {
        opacity: 0,
        y: 50,
        duration: 1,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: '.m3',
          start: 'top bottom-=100'
        }
      })
    },
    p2 () {
      gsap.from('.p2', {
        opacity: 0,
        x: 50,
        duration: 1,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: '.p2',
          start: 'top bottom-=200'
        }
      })
    },
    p3 () {
      gsap.from('.p3', {
        opacity: 0,
        x: -50,
        duration: 1,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: '.p3',
          start: 'top bottom-=200'
        }
      })
    }
  }
}
</script>

<style>

.info-one{
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: end;

  height: 100vh;
}

.info-one .background{
  position: absolute;
  width: 100%;
  height: 100vh;
}

.info-one .background img{
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 8;
}

.info-one .background .gradient-overlay{
  position: absolute;
  inset: 0 0 0 0;
  background: none;
}

.info-one .text{
  width: 100%;
  padding: 1em 5%;
  background-color: white;
  z-index: 100;
}

.info-one .logo{
  display: none;
}

@media only screen and (min-width: 760px) {
  .info-one{
    justify-content: center;
    align-items: center;
  }

  .info-one .background .gradient-overlay{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.98) 10%, rgba(255,255,255,0.8) 30%, rgba(255,255,255,0) 60%);
  }

  .info-one .text{
    background-color: transparent;
    color: white;
  }

  .info-one .logo{
    display: block;
    height: 180px;
    width: 180px;
  }

  .info-one h1 {
    font-size: 80px;
    font-weight: 500;
  }
}

.info-two{
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-content: flex-end;
  position: relative;
  height: 100vh;
}

.info-two img{
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.info-two div{
  width: 100%;
  padding: 1em 5%;
  background-color: white;
  z-index: 500;
}

.info-two a {
  display: inline-block;
  margin-top: 5%;
}

@media only screen and (min-width: 760px){
  .info-two{
    flex-flow: row;
    padding: 80px 0 80px 0;
    height: auto;
  }

  .info-two img{
    position: relative;
    width: 47.5%;
    height: 90%;
    margin: auto 0;
    border-radius: 5px;
  }

  .info-two div{
    width: 30%;
    margin: auto auto;
    padding: 1em 1em;
    text-align: center;
  }
}

</style>
