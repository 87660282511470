<template>
  <div class="contact-img-wrapper">
    <img src="@/assets/cts.png" alt="Contact" class="contact-img">
  </div>
</template>

<script>
export default {
  name: 'Kontakt'
}
</script>

<style>

.contact-img{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 270px;
  height: 270px;
  transform: translate(-50%, -50%);
  image-rendering: optimizeQuality;
}

.contact-img-wrapper{
  height: 100vh;
}

@media only screen and (min-width: 760px){
  .contact-img{
    width: 420px;
    height: 420px;
  }
}

</style>
