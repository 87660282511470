<template>
  <div class="nav-spacer"/>
  <div class="gallery">
    <div class="background"/>
    <div class="category-bar" ref="categoryBar">
      <button class="btn" :class="{active: option===0}" @click="setOption(0)">Realizácie</button>
      <button class="btn" :class="{active: option===1}" @click="setOption(1)">Vizualizácie</button>
    </div>
    <div class="mason-grid">
<!--  OPTION-0:   -->
      <div class="grid-item" v-for="i in 12" :key="i" v-if="option===0">
        <img :src="require('@/assets/photos/nzm/nz'+i+'.jpg')" @load="reloadMasonry">
        <p>Realizácia bytu - Praha</p>
      </div>
      <div class="grid-item" v-for="i in 19" :key="i" v-if="option===0">
        <img :src="require('@/assets/photos/brbyt/'+i+'.jpg')" @load="reloadMasonry">
        <p>Realizácia bytu - Ružomberok</p>
      </div>
      <div class="grid-item" v-for="i in 7" :key="i" v-if="option===0">
        <img :src="require('@/assets/photos/batizovce/'+i+'.jpg')" @load="reloadMasonry">
        <p>Projekt Batizovce</p>
      </div>
      <div class="grid-item" v-for="i in 6" :key="i" v-if="option===0">
        <img :src="require('@/assets/photos/liptk_humno/'+i+'.jpg')" @load="reloadMasonry">
        <p>Humno - Lipt. Klačany</p>
      </div>
      <div class="grid-item" v-for="i in 4" :key="i" v-if="option===0">
        <img :src="require('@/assets/photos/rk_polydom/'+i+'.jpg')" @load="reloadMasonry">
        <p>Polyfunkčný dom - Ružomberok</p>
      </div>
      <div class="grid-item" v-for="i in 6" :key="i" v-if="option===0">
        <img :src="require('@/assets/photos/safir/'+i+'.jpg')" @load="reloadMasonry">
        <p>Firemné Sídlo - Ružomberok</p>
      </div>
      <div class="grid-item" v-for="i in 9" :key="i" v-if="option===0">
        <img :src="require('@/assets/photos/kubo/'+i+'.jpg')" @load="reloadMasonry">
        <p>Byt - Vrútky</p>
      </div>
<!--  OPTION-1:   -->
      <div class="grid-item" v-for="i in 8" :key="i" v-if="option===1">
        <img :src="require('@/assets/renders/liptmdom1/'+i+'.jpg')" @load="reloadMasonry">
        <p>Rodinný dom - Lipt. Michal</p>
      </div>
      <div class="grid-item" v-for="i in 6" :key="i" v-if="option===1">
        <img :src="require('@/assets/renders/kupelna1/'+i+'.jpg')" @load="reloadMasonry">
        <p>Rekonštrukcia byt - Vrútky</p>
      </div>
      <div class="grid-item" v-for="i in 4" :key="i" v-if="option===1">
        <img :src="require('@/assets/renders/liptmdom2/'+i+'.jpg')" @load="reloadMasonry">
        <p>Rodinný dom - Bešeňová</p>
      </div>
      <div class="grid-item" v-for="i in 4" :key="i" v-if="option===1">
        <img :src="require('@/assets/renders/spalen/'+i+'.jpg')" @load="reloadMasonry">
        <p>Rekonštrukcia byt - Vrútky</p>
      </div>
      <div class="grid-item" v-for="i in 2" :key="i" v-if="option===1">
        <img :src="require('@/assets/renders/spalen2/'+i+'.jpg')" @load="reloadMasonry">
        <p>Rekonštrukcia rd - Lipt. Michal</p>
      </div>
      <div class="grid-item" v-for="i in 8" :key="i" v-if="option===1">
        <img :src="require('@/assets/renders/visoft/'+i+'.jpg')" @load="reloadMasonry">
        <p>Drevenica - Kráľova Lehota</p>
      </div>
      <div class="grid-item" v-for="i in 4" :key="i" v-if="option===1">
        <img :src="require('@/assets/renders/texicom/'+i+'.jpg')" @load="reloadMasonry">
        <p>Polyfunkčná budova - Ružomberok</p>
      </div>
      <div class="grid-item" v-for="i in 4" :key="i" v-if="option===1">
        <img :src="require('@/assets/renders/balti/'+i+'.jpg')" @load="reloadMasonry">
        <p>Byt - Praha</p>
      </div>
      <div class="grid-item" v-for="i in 5" :key="i" v-if="option===1">
        <img :src="require('@/assets/renders/mezo/'+i+'.jpg')" @load="reloadMasonry">
        <p>Mezonet - Ružomberok</p>
      </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import Masonry from 'masonry-layout'
import Footer from '../components/Footer'

export default {
  name: 'Galeria',
  components: {
    Footer,
  },
  data: () => {
    return {
      masonry: {},
      option: 0
    }
  },
  mounted () {
    this.option = 0;
    let grid = document.querySelector('.mason-grid')
    this.masonry = new Masonry(grid, {
      itemSelector: '.grid-item',
      columnWidth: 300,
      isFitWidth: true,
      gutter: 10
    })

    addEventListener('resize', this.reloadMasonry);
  },
  methods: {
    reloadMasonry () {
      this.masonry.reloadItems();
      this.masonry.layout();

      if(!this.$refs.categoryBar)
        return;

      if(window.innerWidth <= 760){
        this.$refs.categoryBar.style.marginRight = 0 + 'px';
        return;
      }

      let firstGridItem = document.querySelector('.grid-item');
      let gallery = document.querySelector('.gallery');
      if(!firstGridItem || !gallery)
        return;

      let spacing = firstGridItem.getBoundingClientRect().left - gallery.getBoundingClientRect().left;
      this.$refs.categoryBar.style.marginRight = spacing + 'px';
    },
    setOption(choice){
      this.option = choice;
      this.reloadMasonry();
    }
  }
}

</script>

<style>

.category-bar{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0 0 15px 0;
  margin-right: 0;
}

.category-bar .btn{
  padding: 5px 15px;
  margin-right: 5px;
  border-radius: 5px;
  font-size: small;
}

.category-bar .btn.active{
  color: white;
  background-color: var(--secondary);
}

@media only screen and (min-width: 760px) {
  .category-bar{
    justify-content: flex-end;
  }

  .category-bar .btn{
    font-size: inherit;
  }
}

.gallery{
  position: relative;
}

.gallery .background{
  position: absolute;
  inset: 0 0 0 0;
  background-color: white;
}

.mason-grid{
  z-index: 500;
  height: auto;
  margin: 0 auto;
}

.grid-item{
  position: relative;
  width: 300px;
  margin: 0 auto;
  color: transparent;
}

.grid-item:hover p{
  color: white;
}

.grid-item img{
  width: 100%;
  margin: 0 auto;
}

.grid-item p{
  color: transparent;
  position: absolute;
  bottom: 20px;
  right: 10px;
  padding: 5px;
  width: auto;
  height: auto;
  font-size: 0.9em;
  text-align: right;
}

</style>
