<template>
  <div class="nav-spacer"/>
  <div>
    <div class="partnersFixedMessage">
      <p>ROVNAKO AKO VY, SME VEĽMI NÁROČNÍ PRI VÝBERE NAŠICH PARTNEROV...</p>
    </div>
    <div class="partnersGrid">
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/grenstave.svg" onclick="location.href = 'http://www.grenstave.sk/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/hilti.svg" onclick="location.href = 'https://www.hilti.sk/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/rehau.svg" onclick="location.href = 'https://www.rehau.com/sk-sk'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/festool.svg" onclick="location.href = 'https://www.festool.sk/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/grohe-2.svg" onclick="location.href = 'https://www.grohe.sk/sk_sk'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/zehnder.svg" onclick="location.href = 'https://www.zehnder.cz/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/geberit.svg" onclick="location.href = 'https://www.geberit.sk/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/catalano.svg" onclick="location.href = 'https://www.catalano.cz/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/villeroy-boch.svg" onclick="location.href = 'https://www.villeroy-boch.co.uk/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/huppe.svg" onclick="location.href = 'https://www.hueppe.com/cz/cz/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/mapei.svg" onclick="location.href = 'https://www.mapei.com/sk/sk'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/rigips.svg" onclick="location.href = 'https://www.rigips.sk/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/duravit.svg" onclick="location.href = 'https://www.duravit.cz/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/botament.svg" onclick="location.href = 'https://www.botament.cz/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/knauf.svg" onclick="location.href = 'https://www.knauf.sk/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/schiedel.svg" onclick="location.href = 'https://www.schiedel.com/sk'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/ytong.svg" onclick="location.href = 'https://www.ytong.sk/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/tondach-1.svg" onclick="location.href = 'https://www.wienerberger.sk/produkty/strecha/skridly-tondach.html'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/velux-1.svg" onclick="location.href = 'https://www.velux.sk/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/keim.svg" onclick="location.href = 'https://www.keim.com/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/san-marco-seeklogo.com.svg" onclick="location.href = 'https://sanmarco.sk/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/fatra.svg" onclick="location.href = 'https://www.fatraizolfa.sk/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/caparol.svg" onclick="location.href = 'https://www.caparol.sk/'">
      </div>
      <div class="partnersGridListing">
        <img src="@/assets/company-logos/doka.svg" onclick="location.href = 'https://www.doka.com/sk/index'">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Partneri'
}
</script>

<style>

.partnersFixedMessage{
  width: 100%;
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partnersFixedMessage p{
  margin: 0 5%;
  text-align: center;
}

.partnersGrid{
  overflow-x: hidden;
  margin: 0 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  height: fit-content;
}

.partnersGridListing{
  display: block;
  width: 120px;
  height: 120px;
  margin: 50px;
  position: relative;
}

.partnersGridListing img{
  height: 100%;
  width: 100%;
  object-fit: contain;

  filter: grayscale(1);
  transition: 300ms ease;
  cursor: pointer;
}

.partnersGridListing img:hover{
  filter: grayscale(0);
  width: 102%;
  height: 102%;
}

</style>
